<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/columnClassify' }">栏目分类</el-breadcrumb-item>
          <el-breadcrumb-item>编辑分类</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="栏目名称" prop="module">
            <el-input v-model="form.module" placeholder="请输入栏目名称" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="form.sort" placeholder="数字越大顺序越靠后"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
            <el-button size="small" @click="$router.push('/columnClassify')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        module:[{ required: true, message: '必填项', trigger: 'blur' }],
        sort:[{ required: true, message: '必填项', trigger: 'blur' }],
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form = {
            id: this.form.id,
            module: this.form.module,
            sort: parseInt(this.form.sort)
          }
          this.loading = true
          this.$ajax.post('columnEdit', form).then(res => {
            this.loading = false
            this.$message.success('成功')
            this.$router.push('/columnClassify')
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    if (this.$route.params == null) {
      this.$router.push('/columnClassify')
    } else {
      this.form = this.$route.params
    }
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
</style>